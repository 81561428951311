import { defineComponent, onUnmounted } from '@vue/composition-api';
import EventBus from '@/shared/services/eventBus';
import useStructuredData from '@/shared/composables/useStructuredData';
export default defineComponent({
    setup() {
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        EventBus.$on('onBreadcrumbsReady', data => {
            injectStructuredData({
                '@context': 'https://schema.org',
                ...data
            });
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
    }
});
